import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getExpenseTags() {
    return axiosIns.get('/waiter/expense_tags')
  },
  createExpenseTag({ name, type }) {
    const formData = serialize(snakecaseKeys({
      [type]: { name },
    }))

    return axiosIns.post('/waiter/expense_tags', formData)
  },
  updateExpenseTag({ id, name, type }) {
    const formData = serialize(snakecaseKeys({
      [type]: { id, name },
    }))

    return axiosIns.put(`/waiter/expense_tags/${id}`, formData)
  },
  deleteExpenseTag({ id, type }) {
    return axiosIns.delete(`/waiter/expense_tags/${id}?type=${type}`)
  },
}
