import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getBenefitTags() {
    return axiosIns.get('/waiter/benefit_tags')
  },
  createBenefitTag({ name, type }) {
    const formData = serialize(snakecaseKeys({
      [type]: { name },
    }))

    return axiosIns.post('/waiter/benefit_tags', formData)
  },
  updateBenefitTag({ id, name, type }) {
    const formData = serialize(snakecaseKeys({
      [type]: { id, name },
    }))

    return axiosIns.put(`/waiter/benefit_tags/${id}`, formData)
  },
  deleteBenefitTag({ id, type }) {
    return axiosIns.delete(`/waiter/benefit_tags/${id}?type=${type}`)
  },
}
