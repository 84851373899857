import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getVendingHistories({ date, interval, endDate = null }) {
    return axiosIns.get(
      '/v2/data_export/vending_histories',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
    )
  },
}
